//创建原型分组列表
export const prctureGroupListCols = [
  {
    prop: 'name',
    label: '分组名称',
    slotName: 'nameSlot'
  },
  {
    prop: 'count',
    label: '分组图片数'
  },
  {
    prop: 'isDefault',
    label: '默认分组',
    slotName: 'isDefaultSlot'
  },
  {
    prop: 'enable',
    label: '状态',
    slotName: 'enableSlot'
  },
  {
    prop: 'createTime',
    label: '创建时间'
  }
]
