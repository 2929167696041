//图片组field
export const pictureGroupListField = [
  {
    label: '分组名称',
    prop: 'name',
    colNum: 6,
    placeholder: '请输入分组名称'
  },
  {
    label: '图片名称',
    prop: 'imageTitle',
    colNum: 6,
    placeholder: '请输入图片名称'
  },
  {
    label: '状态',
    prop: 'enable',
    colNum: 6,
    slotName: 'enableSlot',
    placeholder: '请选择状态'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]
