<template>
  <colorTextBtn @click="onCopy">
    复制图片
  </colorTextBtn>
</template>

<script>
import { getPictureGroupDetail } from '@/api/image/pictureGroupApi'
import { createPictureGroup } from '@/api/image/pictureGroupApi'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    async onCopy() {
      const confirm = await awaitFormResolve(
        this.$confirm('确认需要复制图片分组吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      )
      if (!confirm) return
      const res = await this.getDetail()
      if (!res) return
      const imageIdList = $GET(res, 'imageIdList', [])
      const { name } = this.data
      const copyName = `${name} 副本`
      const res1 = await awaitResolveDetailLoading(createPictureGroup({
        name: copyName,
        imageIdList
      }))
      if (!res1) return
      this.$message.success('操作成功')
      this.$emit('successHandler')
    },

    async getDetail() {
      const { id } = this.data
      return awaitResolveDetailLoading(getPictureGroupDetail({ id }))
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
