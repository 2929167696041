<template>
  <BasePictureGroupPage :formField="formField" :cols="cols" :resetUrl="resetUrl"  v-bind="permissionList" >
    <template #menu="{row, sup_this}">
      <CopyPicture v-if="permissionList.hasCopyPictureBtn" :data="row" @successHandler="sup_this.init"/>
    </template>
  </BasePictureGroupPage>
</template>

<script>
import BasePictureGroupPage from '../basePictureGroupPage'
import CopyPicture from './module/copyPicture'
import { pictureGroupListField as formField } from './field'
import { prctureGroupListCols as cols } from './cols'
import {checkPermission} from '@/utils'

export default {
  components: {
    BasePictureGroupPage,
    CopyPicture
  },

  data() {
    return {
      formField,
      cols,
      resetUrl: '/externaladmin/productService/imageGroup/list'
    }
  },


  computed: {
    permissionList() {
      return {
        hasAddPictureGroup: checkPermission(['externaladmin:publicGalleryGroup:addGroup']),
        hasAssignDistributor: checkPermission(['externaladmin:publicGalleryGroup:assignProtoDistributor']),
        hasEditPictureGroup: checkPermission(['externaladmin:publicGalleryGroup:editGroup']),
        hasEnablePictureBtn: checkPermission(['externaladmin:publicGalleryGroup:enable']),
        hasDisablePictureBtn: checkPermission(['externaladmin:publicGalleryGroup:disable']),
        hasCopyPictureBtn: checkPermission(['externaladmin:publicGalleryGroup:copy'])
      }
    }
  }
}
</script>

<style>
</style>
